import React, {useState, useContext} from "react";
import {AuthContext} from "../../context/AuthContext";
import {AuthScreen} from "../auth/authScreen";
import {Sidebar} from "../../сomponents/sidebar/sidebar";
import {Product} from "../../assets/Product";
import {Category} from "../../assets/Category";
import {Material} from "../../assets/Material";
import {Size} from "../../assets/Size";
import {Decorate} from "../../assets/Decorate";
import {Proba} from "../../assets/Proba";
import {ProductPage} from "../product/ProductPage";
import {CategoryPage} from "../category/CategoryPage";
import {MaterialPage} from "../material/MaterialPage";
import {SizePage} from "../size/SizePage";
import {DecorPage} from "../decorate/DecorPage";
import {ProbaPage} from "../proba/ProbaPage";
import {ShopPage} from "../shop/ShopPage";
import {Shop} from "../../assets/Shop";
import {Money} from "../../assets/Money";
import {CertificatePage} from "../certificate/CertificatePage";
import {CertificateTypePage} from "../certificateType/CertificateTypePage";
import {Certificate} from "../../assets/Certificate";
import {ClientPage} from "../client/ClientPage";
import {User} from "../../assets/User";
import classes from "./styles.module.scss";
import {PromoCodePage} from "../promocode/PromoCodePage";
import {Promocode} from "../../assets/Promocode";
import {NewsPage} from "../news/NewsPage";
import {JournalPage} from "../journal/JournalPage";
import {Folder} from "../../assets/Folder";
import {News} from "../../assets/News";
import {Delivery} from "../../assets/Delivery";
import {PackageList} from "../../assets/Package";
import {DeliveryTypePage} from "../deliveryType/DeliveryTypePage";
import {DeliveryPage} from "../delivery/DeliveryPage";
import {OrderPage} from "../order/OrderPage";
import {Order} from "../../assets/Order";
import {HomePage} from "../home/HomePage";
import {Home} from "../../assets/Home";
import {PromoPage} from "../promo/PromoPage";

const items = [
    {
        label: "Главная",
        key: "1",
        icon: <Home/>,
        content: <HomePage />
    },
    {
        label: "Пользователи",
        key: "2",
        icon: <User/>,
        content: <ClientPage />
    },
    {
        label: "Контент",
        key: "sub5",
        items: [
            {label: "Промо", key: "3", icon: <Product/>, content: <PromoPage/>},
            // {label: "Новинка", key: "5", icon: <Material/>, content: <MaterialPage/>},
        ]
    },
    {
        label: "Товары",
        key: "sub1",
        items: [
            {label: "Товары", key: "3", icon: <Product/>, content: <ProductPage/>},
            {label: "Категории", key: "4", icon: <Category/>, content: <CategoryPage/>},
            {label: "Материалы", key: "5", icon: <Material/>, content: <MaterialPage/>},
            {label: "Размер", key: "6", icon: <Size/>, content: <SizePage/>},
            {label: "Вставка", key: "7", icon: <Decorate/>, content: <DecorPage/>},
            {label: "Проба", key: "8", icon: <Proba/>, content: <ProbaPage/>},
        ]
    },
    {
        label: "Сертификаты и промокоды",
        key: "sub2",
        items: [
            { label: "Номинал", key: "10", icon: <Money/>, content: <CertificateTypePage /> },
            { label: "Сертификаты", key: "11", icon: <Certificate/>, content: <CertificatePage/> },
            { label: "Промокоды", key: "12", icon: <Promocode/>, content: <PromoCodePage /> },
        ],
    },
    {
        label: "Новости",
        key: "sub3",
        items: [
            { label: "Журналы", key: "13", icon: <Folder/>, content: <JournalPage /> },
            { label: "Новости", key: "14", icon: <News/>, content: <NewsPage /> },
        ],
    },
    {
        label: "Доставка",
        key: "sub4",
        items: [
            { label: "Магазины", key: "15", icon: <Shop />, content: <ShopPage /> },
            { label: "Заказы", key: "16", icon: <Order/>, content: <OrderPage /> },
            { label: "Способы доставки", key: "17", icon: <Delivery/>, content: <DeliveryTypePage/> },
            { label: "Доставка", key: "18", icon: <PackageList />, content: <DeliveryPage /> },
        ],
    },
];

const MainPage = () => {
    const {isAuth, loadingAuth} = useContext(AuthContext);
    const [openSideBar, setOpenSideBar] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);

    if(loadingAuth) return <div className={classes.loader}></div>

    return <>
        {isAuth
            ? <div className="flex h-screen flex-col border-e bg-white">
                <div className="p-4">
                    <button onClick={() => setOpenSideBar(true)}
                        aria-controls="default-sidebar" type="button"
                        className="inline-flex items-center p-2 mt-2 ms-3 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600">
                        <span className="sr-only">Open sidebar</span>
                        <svg className="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20"
                             xmlns="http://www.w3.org/2000/svg">
                            <path clipRule="evenodd" fillRule="evenodd"
                                  d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
                        </svg>
                    </button>
                </div>

                <Sidebar onActive={openSideBar} items={items} setSelected={setSelectedItem} setOnActive={setOpenSideBar} />

                <div className="p-4 sm:ml-[250px]">
                    {selectedItem ? selectedItem.content : items[0].content}
                </div>
            </div>
            : <AuthScreen/>
        }
    </>
};

export default MainPage;
