import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

const MainSlider = ({ sliders, modile }) => {
    return (
        <div className='relative w-full h-[500px] overflow-hidden'>


            <div className='absolute left-0 top-0 w-full  bg-gradient-to-t from-transparent to-black opacity-20 z-[20]'></div>
            <Swiper
                modules={[Autoplay, Pagination]}
                className='relative w-full h-[500px]'
                slidesPerView={1}
                loop
                allowTouchMove
                pagination={{ clickable: true }}
                autoplay={{ delay: 10000 }}
            >
                {sliders.map((slide) => (
                    <SwiperSlide key={slide.position}>
                        {console.log(slide)}
                        <div className={`relative h-[500px] w-full flex ${modile ? 'flex-col' : 'flex-row'} `}>
                            <div className={`absolute z-[50] ${modile ? 'bottom-[56px] left-4 max-w-[342px] gap-4' : 'bottom-[148px] left-1/2 -translate-x-1/2 max-w-[540px] gap-6 items-center'} flex flex-col justify-center `}>
                                <div className={`${modile ? '' : 'hidden' } text-2xl text-white font-palatino'`}>
                                    {slide.title}
                                </div>

                                <div className={`${modile ? 'hidden' : 'block' }  text-4xl text-white font-palatino`}>
                                    {slide.title}
                                </div>

                                <a href='/search'>
                                    <div className='bg-[#cf9339] text-white border-[1px] border-transparent hover:border-[1px] hover:border-red px-12 py-[10px] text-base font-proxima flex justify-center items-center w-[184px]'>Посмотреть</div>
                                </a>
                            </div>

                            {slide.images.map((file, index) => (
                                <div
                                    key={file.id}
                                    className={`
                                        ${(slide.images.length === 2
                                        ? modile ? 'w-full h-1/2 ' : 'w-1/2 h-[500px]'
                                        : 'w-full h-[500px]') +
                                    ' relative'}
                                    `}
                                >
                                    {file.type_file === 'image' ? (
                                        <img
                                            src={
                                                process.env.REACT_APP_API_BACKEND + "/public/" +
                                                file.path
                                            }
                                            className='object-fill w-full h-auto'
                                            loading='eager'
                                            alt={file.name}
                                            sizes='(max-width: 890px) 50vw, (min-width: 890px) 100vw, 100%'
                                        />
                                    ) : (
                                        <video
                                            className='w-full object-cover h-full'
                                            autoPlay={true}
                                            muted
                                            loop
                                            preload='none'
                                        >
                                            <source
                                                src={
                                                    process.env.REACT_APP_API_BACKEND + "/public/" +
                                                    file.path
                                                }
                                                type='video/mp4'
                                            />
                                        </video>
                                    )}
                                </div>
                            ))}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

        </div>
    );
};

export default MainSlider;
