import axios from 'axios'

export const instance = axios.create({
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_BACKEND,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
        'Access-Control-Allow-Headers':
            'Content-Type, Authorization, X-Requested-With',
    },
})
