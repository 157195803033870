import React, { useState, useEffect, useMemo, useCallback } from "react";
import { FileService } from "../../service/fileService";
import { SlideService } from "../../service/slideService";
import MainSlider from "./sliders/main_slider";

export const PromoPage = () => {
    const [slides, setSlides] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [fileToPreview, setFileToPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [newSlideTitle, setNewSlideTitle] = useState("");
    const [fullscreenPreview, setFullscreenPreview] = useState(null);
    const [editingSlideId, setEditingSlideId] = useState(null);
    const [editedTitle, setEditedTitle] = useState("");

    const fileService = useMemo(() => new FileService(), []);
    const slideService = useMemo(() => new SlideService(), []);

    const getAllSlides = useCallback(async () => {
        try {
            const response = await slideService.get();
            // Sort slides by position to ensure correct order
            const sortedSlides = response.data.sort((a, b) => a.position - b.position);
            setSlides(sortedSlides);
        } catch (error) {
            console.error("Ошибка при загрузке слайдов:", error);
        }
    }, [slideService]);

    const createSlide = useCallback(async () => {
        try {
            if (newSlideTitle.trim()) {
                await slideService.create({ title: newSlideTitle, position: slides.length + 1 });
                setNewSlideTitle("");
                getAllSlides();
            }
        } catch (error) {
            console.error("Ошибка при создании слайда:", error);
        }
    }, [newSlideTitle, slides.length, slideService, getAllSlides]);

    const updateSlide = useCallback(async (slideId, data) => {
        try {
            await slideService.edit(slideId, data);
            getAllSlides();
        } catch (error) {
            console.error("Ошибка при обновлении слайда:", error);
        }
    }, [slideService, getAllSlides]);

    const deleteSlide = useCallback(async (slideId) => {
        try {
            await slideService.delete(slideId);
            getAllSlides();
        } catch (error) {
            console.error("Ошибка при удалении слайда:", error);
        }
    }, [slideService, getAllSlides]);

    useEffect(() => {
        getAllSlides();
    }, [getAllSlides]);

    const handleFileChange = (event, imagePosition, slideId) => {
        const file = event.target.files[0];
        setSelectedFile({ file, slideId, imagePosition });
        setFileToPreview(URL.createObjectURL(file));
    };

    const handleFileUpload = async () => {
        if (selectedFile) {
            setLoading(true);
            const formData = new FormData();
            formData.append("file", selectedFile.file);
            formData.append("entity_type", "main_banner");
            formData.append("entity_id", selectedFile.slideId);
            formData.append("position", selectedFile.imagePosition);

            try {
                await fileService.load(formData);
                setSelectedFile(null);
                setFileToPreview(null);
                getAllSlides();
            } catch (error) {
                console.error("Ошибка при загрузке файла:", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDeleteFile = async (fileId) => {
        try {
            await fileService.delete(fileId);
            getAllSlides();
        } catch (error) {
            console.error("Ошибка при удалении файла:", error);
        }
    };

    const handleCancelPreview = () => {
        setSelectedFile(null);
        setFileToPreview(null);
    };

    const handleFullscreenPreview = (file) => {
        setFullscreenPreview(file);
    };

    const closeFullscreenPreview = () => {
        setFullscreenPreview(null);
    };

    const startEditingTitle = (slide) => {
        setEditingSlideId(slide.id);
        setEditedTitle(slide.title);
    };

    const saveEditedTitle = async (slideId) => {
        if (editedTitle.trim()) {
            await updateSlide(slideId, { title: editedTitle });
            setEditingSlideId(null);
            setEditedTitle("");
        }
    };

    const cancelEditingTitle = () => {
        setEditingSlideId(null);
        setEditedTitle("");
    };

    const moveSlideUp = async (index) => {
        if (index > 0) {
            const currentSlide = slides[index];
            const aboveSlide = slides[index - 1];

            // Swap positions
            await updateSlide(currentSlide.id, { position: aboveSlide.position });
            await updateSlide(aboveSlide.id, { position: currentSlide.position });
        }
    };

    const moveSlideDown = async (index) => {
        if (index < slides.length - 1) {
            const currentSlide = slides[index];
            const belowSlide = slides[index + 1];

            console.log(currentSlide)
            console.log(belowSlide)

            // Swap positions
            await updateSlide(currentSlide.id, { position: belowSlide.position });
            await updateSlide(belowSlide.id, { position: currentSlide.position });
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Промо-слайды</h2>

            <div className="mb-6">
                <input
                    type="text"
                    placeholder="Название нового слайда"
                    value={newSlideTitle}
                    onChange={(e) => setNewSlideTitle(e.target.value)}
                    className="border rounded p-2 mr-2"
                />
                <button
                    onClick={createSlide}
                    className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                    Добавить слайд
                </button>
            </div>

            {slides.map((slide, index) => (
                <div key={slide.id} className="mb-6 border p-4 rounded">
                    <div className="flex items-center justify-between mb-4">
                        {editingSlideId === slide.id ? (
                            <div className="flex items-center">
                                <input
                                    type="text"
                                    value={editedTitle}
                                    onChange={(e) => setEditedTitle(e.target.value)}
                                    className="border rounded p-2 mr-2"
                                />
                                <button
                                    onClick={() => saveEditedTitle(slide.id)}
                                    className="bg-green-500 text-white px-2 py-1 rounded mr-2"
                                >
                                    Сохранить
                                </button>
                                <button
                                    onClick={cancelEditingTitle}
                                    className="bg-gray-500 text-white px-2 py-1 rounded"
                                >
                                    Отмена
                                </button>
                            </div>
                        ) : (
                            <h3 className="font-bold">
                                Слайд {slide.position}: {slide.title}
                            </h3>
                        )}
                        <div className="flex items-center">
                            <button
                                onClick={() => moveSlideUp(index)}
                                className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                                disabled={index === 0}
                            >
                                Вверх
                            </button>
                            <button
                                onClick={() => moveSlideDown(index)}
                                className="bg-blue-500 text-white px-2 py-1 rounded mr-2"
                                disabled={index === slide}
                            >
                                Вниз
                            </button>
                            <button
                                onClick={() => startEditingTitle(slide)}
                                className="bg-yellow-500 text-white px-2 py-1 rounded mr-2"
                            >
                                Редактировать
                            </button>
                            <button
                                onClick={() => deleteSlide(slide.id)}
                                className="bg-red-500 text-white px-2 py-1 rounded"
                            >
                                Удалить
                            </button>
                        </div>
                    </div>
                    <div className="flex">
                        {slide.images.map((file) => (
                            <div key={file.id} className="relative mr-4">
                                {file.type_file === "video" ? (
                                    <video
                                        autoPlay
                                        muted
                                        controls
                                        src={`${process.env.REACT_APP_API_BACKEND}/public${file.path}`}
                                        className="w-32 h-32 cursor-pointer"
                                        onClick={() => handleFullscreenPreview(file)}
                                    />
                                ) : (
                                    <img
                                        src={`${process.env.REACT_APP_API_BACKEND}/public${file.path}`}
                                        alt="Файл"
                                        className="w-32 h-32 cursor-pointer"
                                        onClick={() => handleFullscreenPreview(file)}
                                    />
                                )}
                                <button
                                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                                    onClick={() => handleDeleteFile(file.id)}
                                >
                                    Удалить
                                </button>
                            </div>
                        ))}
                        {slide.images.length < 2 && (
                            <div className="w-32 h-32 bg-gray-100 flex items-center justify-center">
                                <label
                                    htmlFor={`upload-${slide.id}-${slide.images.length}`}
                                    className="text-center cursor-pointer"
                                >
                                    Выбрать файл
                                </label>
                                <input
                                    id={`upload-${slide.id}-${slide.images.length}`}
                                    type="file"
                                    className="hidden"
                                    onChange={(e) => handleFileChange(e, slide.images.length, slide.id)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            ))}

            {loading && (
                <div className="mt-4 flex items-center justify-center">
                    <div className="loader border-4 border-t-4 border-gray-200 rounded-full w-8 h-8 animate-spin"></div>
                </div>
            )}

            {fileToPreview && (
                <div className="mt-4">
                    <h3 className="font-bold mb-2">Предпросмотр файла</h3>
                    {selectedFile.file.type.startsWith("video") ? (
                        <video src={fileToPreview} controls className="w-64 h-64" />
                    ) : (
                        <img src={fileToPreview} alt="Предпросмотр" className="w-32 h-32" />
                    )}
                    <div className="flex mt-2">
                        <button
                            onClick={handleFileUpload}
                            className="px-4 py-2 bg-green-500 text-white rounded mr-2"
                        >
                            Загрузить
                        </button>
                        <button
                            onClick={handleCancelPreview}
                            className="px-4 py-2 bg-red-500 text-white rounded"
                        >
                            Отменить
                        </button>
                    </div>
                </div>
            )}

            {fullscreenPreview && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                    <button
                        onClick={closeFullscreenPreview}
                        className="fixed top-4 right-4 bg-red-500 text-white px-4 py-2 rounded z-50"
                    >
                        Закрыть
                    </button>
                    <div className="overflow-auto max-h-screen max-w-screen flex items-center justify-center">
                        {fullscreenPreview.type_file === "video" ? (
                            <video
                                src={`${process.env.REACT_APP_API_BACKEND}/public${fullscreenPreview.path}`}
                                controls
                                className="max-w-full object-contain"
                                style={{ maxHeight: 'calc(100vh - 80px)' }}
                            />
                        ) : (
                            <img
                                src={`${process.env.REACT_APP_API_BACKEND}/public${fullscreenPreview.path}`}
                                alt="Предпросмотр на весь экран"
                                className="max-w-full object-contain"
                                style={{ maxHeight: 'calc(100vh - 80px)' }}
                            />
                        )}
                    </div>
                </div>
            )}

            <div className="mt-8 hidden md:block">
                <h3 className="font-bold mb-4">Предпросмотр:</h3>
                <div className="flex justify-between">
                    <div className="w-4/5 border p-4 rounded">
                        <h4 className="font-bold mb-2">Компьютер</h4>
                        <div className="border p-2 h-[500px] overflow-hidden bg-gray-100">
                            <MainSlider sliders={slides || []} />
                        </div>
                    </div>
                    <div className="w-1/5 border p-4 rounded">
                        <h4 className="font-bold mb-2">Мобильное устройство</h4>
                        <div className="border p-2 h-[500px] overflow-hidden bg-gray-100">
                            <MainSlider mobile={true} sliders={slides || []} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
