import React, {useEffect, useMemo, useState} from "react";
import {CategoryService} from "../../service/categoryService";
import {SizeService} from "../../service/sizeService";
import classes from "./Form.module.scss";
import {ImageManager} from "../../сomponents/imageManager/ImageManager";
import {MaterialService} from "../../service/materialService";
import {DecorService} from "../../service/decorService";
import {ProbaService} from "../../service/probaService";
import {DiscountService} from "../../service/discountService";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const Form = ({item, onCancel, onSave, errors, setErrors}) => {
    const [title, setTitle] = useState(item ? item.title : '');
    const [article, setArticle] = useState(item ? item.article : '');
    const [price, setPrice] = useState(item ? item.price : 0)
    const [available, setAvailable] = useState(item ? item.available : 0)
    const [sex, setSex] = useState(item ? item.sex : '')
    const [description, setDescription] = useState(item ? item.description : '');
    const [details, setDetails] = useState(item ? item.details : '');
    const [categoryId, setCategoryId] = useState(item ? item.category_id : '')
    const [materialId, setMaterialId] = useState(item ? item.material_id : '')
    const [size, setSize] = useState(item ? item.size_ids : [])
    const [decor, setDecor] = useState(item ? item.decoration_ids : [])
    const [proba, setProba] = useState(item ? item.prob_ids : [])
    const [weight, setWeight] = useState(item ? item.weight : 0)
    const [isNew, setIsNew] = useState(item ? item.is_new : false)
    const [isHitOfSales, setIsHitOfSales] = useState(item ? item.hit_if_sales : false)

    const [categories, setCategories] = useState([])
    const [decorates, setDecorates] = useState([])
    const [materials, setMaterials] = useState([])
    const [sizes, setSizes] = useState([])
    const [probs, setProbs] = useState([])

    const [loading, setLoading] = useState(true);

    const [discountPercentage, setDiscountPercentage] = useState(item?.discount ? item.discount.percentage : 0)
    const [activePercentage, setActivePercentage] = useState(item?.discount ? item.discount.activated : false)
    const [createDatePercentage, setCreateDatePercentage] = useState(item?.discount ? new Date() : 0)
    const [endDatePercentage, setEndDatePercentage] = useState(item?.discount ? new Date() : 0)

    const categoryService = useMemo( () => new CategoryService(), [])
    const materialService = useMemo( () => new MaterialService(), [])
    const sizeService = useMemo( () => new SizeService(), [])
    const decorService = useMemo( () => new DecorService(), [])
    const probaService = useMemo( () => new ProbaService(), [])
    const discountService = useMemo( () => new DiscountService(), [])


    const sexs = [
        {
            value: "male",
            label: "Мужской"
        },
        {
            value: "female",
            label: "Женский"
        },
        {
            value: "all",
            label: "Унисекс"
        },
    ]

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [categoriesRes, materialsRes, sizesRes, decorRes, probRes] = await Promise.all([
                    categoryService.getAll(),
                    materialService.getAll(),
                    sizeService.getAll(),
                    decorService.getAll(),
                    probaService.getAll()
                ]);

                if (categoriesRes.success) setCategories(categoriesRes.data);
                if (materialsRes.success) setMaterials(materialsRes.data);
                if (sizesRes.success) setSizes(sizesRes.data);
                if (decorRes.success) setDecorates(decorRes.data);
                if (probRes.success) setProbs(probRes.data);

            } catch (error) {
                console.error("Ошибка загрузки данных:", error);
            } finally {
                setLoading(false); // завершение загрузки
            }
        };

        fetchData().then(r => console.log(r));
    }, [categoryService, decorService, materialService, probaService, sizeService]);




    const handleSubmit = () => {
        setErrors([])
        const prodData = {id: item ? item.id : undefined, title, article, price, available, sex, description, details, weight: weight, hit_if_sales: isHitOfSales, is_new: isNew, category_id: categoryId, material_id: materialId, prob_ids: proba, decoration_ids: decor, size_ids: size};
        onSave(prodData);
    }


    const handleSubmitDiscount = async () => {
        !!!item?.discount?.id ?
            await discountService.create({
                productId: item ? item.id : '',
                startDate: createDatePercentage,
                endDate: endDatePercentage,
                active: activePercentage,
                percentage: discountPercentage
            })
            :
            await discountService.edit({
                id: item?.discount?.id,
                productId: item ? item.id : '',
                startDate: createDatePercentage,
                endDate: endDatePercentage,
                active: activePercentage,
                percentage: discountPercentage
            })
    }

    if (loading) {
        return <div className={classes.loader}></div>; // можно заменить на более красивую анимацию
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'space-between'}}>
            <div className={classes.formActions}>
                <button style={{marginTop: '10px'}} className={classes.cancel} onClick={onCancel}>Назад</button>
            </div>
            <div className={classes.row}>
                <div className={classes.column} style={{maxWidth: '400px', justifyContent: 'start'}}>
                    <div className={classes.cart} style={{minHeight: 'auto', maxHeight: '400px'}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Цена</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>

                                    <tr>
                                        <td>Базовая цена</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={price}
                                                onChange={(e) => {
                                                    setPrice(e.target.value)
                                                    if (e.target.value <= 0)
                                                        setPrice(0)
                                                    setErrors([])
                                                }}
                                                placeholder="Цена"
                                                className={errors.find(err => err.type === 'price') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'price') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'price').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Количество</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={available}
                                                onChange={(e) => {
                                                    setAvailable(e.target.value)
                                                    if (e.target.value <= 0)
                                                        setAvailable(0)
                                                    setErrors([])
                                                }}
                                                placeholder="Количество"
                                                className={errors.find(err => err.type === 'available') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'available') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'available').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Масса</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={weight}
                                                onChange={(e) => {
                                                    setWeight(e.target.value)
                                                    if (e.target.value <= 0)
                                                        setWeight(0)
                                                    setErrors([])
                                                }}
                                                placeholder="Масса"
                                                className={errors.find(err => err.type === 'weight') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'weight') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'weight').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}
                         style={{minHeight: 'auto', maxHeight: '300px', marginTop: '2.5rem',}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Акции</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Новинка</td>
                                        <td className="flex items-center content-center">
                                            <div className={classes.toggleSwitch}>
                                                <input
                                                    type="checkbox"
                                                    id="acceptedCheckbox"
                                                    checked={isNew}  // это ваш boolean state
                                                    onChange={(e) => {
                                                        setIsNew(!isNew)
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Хит продаж</td>
                                        <td className="flex items-center content-center">
                                            <div className={classes.toggleSwitch}>
                                                <input
                                                    type="checkbox"
                                                    checked={isHitOfSales}
                                                    onChange={(e) => setIsHitOfSales(!isHitOfSales)}
                                                    id="acceptedCheckbox"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <button className={classes.save} onClick={handleSubmit}>Сохранить
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart}
                         style={{minHeight: 'auto', maxHeight: '700px', marginTop: '2.5rem',}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Скидка</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td>Процент (%)</td>
                                        <td>
                                            <input
                                                type="number"
                                                value={discountPercentage}
                                                onChange={(e) => {
                                                    setDiscountPercentage(e.target.value)
                                                    setErrors([])
                                                }}
                                                placeholder="Процент скидки (%)"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Активна</td>
                                        <td>
                                            <div className={classes.toggleSwitch}>
                                                <input
                                                    type="checkbox"
                                                    id="toggleSwitch"
                                                    checked={activePercentage}  // это ваш boolean state
                                                    onChange={(e) => {
                                                        setActivePercentage(!activePercentage)
                                                        setErrors([])
                                                    }}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td>
                                            <button className={classes.save} onClick={handleSubmitDiscount}>Сохранить
                                            </button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.column}>
                    <div className={classes.cart}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Информация</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>

                                    <tr>
                                        <td> Название</td>
                                        <td>
                                            <input
                                                type="text"
                                                value={title}
                                                onChange={(e) => {
                                                    setTitle(e.target.value)
                                                    setErrors([])
                                                }}
                                                placeholder="Название"
                                                className={errors.find(err => err.type === 'title') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'title') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'title').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'left'}}> Артикль</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <input
                                                type="text"
                                                value={article}
                                                onChange={(e) => {
                                                    setArticle(e.target.value)
                                                    setErrors([])
                                                }}
                                                placeholder="Артикль"
                                                className={errors.find(err => err.type === 'article') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'article') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'article').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'left'}}> Описание</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <ReactQuill
                                                value={description}
                                                onChange={(text) => {
                                                    setDescription(text)
                                                    setErrors([])
                                                }}
                                                placeholder="Характеристики"
                                                className={errors.find(err => err.type === 'description') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'description') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'description').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'left'}}> Характеристики</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <ReactQuill
                                                value={details}
                                                onChange={(item) => {
                                                    setDetails(item)
                                                    setErrors([])
                                                }}
                                                placeholder="Характеристики"
                                                className={errors.find(err => err.type === 'details') ? 'error' : ''}
                                            />
                                            {errors.find(err => err.type === 'details') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'details').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    {item?.created_at &&
                                        <tr>
                                            <td>Создано</td>
                                            <td> {new Date(item ? item.created_at.split('T')[0] : '').toLocaleDateString() + " " + new Date(item ? item.created_at.split('T')[0] : '').toLocaleTimeString()} </td>
                                        </tr>
                                    }
                                    {item?.updated_at &&
                                        <tr>
                                            <td>Обновлено</td>
                                            <td> {new Date(item ? item.updated_at.split('T')[0] : '').toLocaleDateString() + " " + new Date(item ? item.updated_at.split('T')[0] : '').toLocaleTimeString()} </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                        <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className={classes.cart} style={{width: '100%', marginTop: '2.5rem'}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Параметры</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <table className={classes.table}>
                                    <tbody className={classes.tbody}>
                                    <tr>
                                        <td> Категория</td>
                                        <td>
                                            <select
                                                value={categoryId}
                                                onChange={(e) => {
                                                    setCategoryId(e.target.value)
                                                    setErrors([])
                                                }}
                                                className={errors.find(err => err.type === 'category_id') ? 'error' : ''}
                                            >
                                                {categories.map(cat => (
                                                    <option key={cat.id} value={cat.id}>
                                                        {cat.title}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.find(err => err.type === 'category_id') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'category_id').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Материал</td>
                                        <td>
                                            <select
                                                value={materialId}
                                                onChange={(e) => {
                                                    setMaterialId(e.target.value)
                                                    setErrors([])
                                                }}
                                                className={errors.find(err => err.type === 'material_id') ? 'error' : ''}
                                            >
                                                {materials.map(mat => (
                                                    <option key={mat.id} value={mat.id}>
                                                        {mat.title}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.find(err => err.type === 'material_id') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'material_id').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Предназначение</td>
                                        <td>
                                            <select
                                                value={sex}
                                                onChange={(e) => {
                                                    setSex(e.target.value)
                                                    setErrors([])
                                                }}
                                                className={errors.find(err => err.type === 'sex') ? 'error' : ''}
                                            >
                                                {sexs.map((cat, index) => (
                                                    <option key={index} value={cat.value}>
                                                        {cat.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {errors.find(err => err.type === 'sex') && (
                                                <p className="error">
                                                    {errors.find(err => err.type === 'sex').message}
                                                </p>
                                            )}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'left'}}>Размеры</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{marginBottom: '10px'}} className={classes.selector}>
                                                {sizes.map((option) => (
                                                    <div
                                                        key={option.id}
                                                        className={`${classes.option} ${size.includes(option.id) ? classes.selected : ''}`}
                                                        onClick={() => {
                                                            if (size.includes(option.id)) {
                                                                setSize(size.filter((id) => id !== option.id));
                                                            } else {
                                                                setSize([...size, option.id]);
                                                            }
                                                        }}
                                                    >
                                                        {option.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'left'}}>Вставка</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{marginBottom: '10px'}} className={classes.selector}>
                                                {decorates.map((option) => (
                                                    <div
                                                        key={option.id}
                                                        className={`${classes.option} ${decor.includes(option.id) ? classes.selected : ''}`}
                                                        onClick={() => {
                                                            if (decor.includes(option.id)) {
                                                                setDecor(decor.filter((id) => id !== option.id));
                                                            } else {
                                                                setDecor([...decor, option.id]);
                                                            }
                                                        }}
                                                    >
                                                        {option.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} style={{textAlign: 'left'}}>Проба</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2}>
                                            <div style={{marginBottom: '10px'}} className={classes.selector}>
                                                {probs.map((option) => (
                                                    <div
                                                        key={option.id}
                                                        className={`${classes.option} ${proba.includes(option.id) ? classes.selected : ''}`}
                                                        onClick={() => {
                                                            if (proba.includes(option.id)) {
                                                                setProba(proba.filter((id) => id !== option.id));
                                                            } else {
                                                                setProba([...proba, option.id]);
                                                            }
                                                        }}
                                                    >
                                                        {option.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <button className={classes.save} onClick={handleSubmit}>Сохранить</button>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                    <div className={classes.cart} style={{width: '100%', marginTop: '2.5rem'}}>
                        <div className={classes.header}>
                            <div className={classes.title}>
                                <h2>Галерея</h2>
                            </div>
                        </div>
                        <div className={classes.body}>
                            <div className={classes.responsive}>
                                <ImageManager
                                    itemImages={item ? item.images : []}
                                    entity_id={item ? item.id : ''}
                                    entity_type={'product'}
                                    saveObject={handleSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
