import React, {useCallback, useEffect, useMemo, useState} from "react";
import classes from './NewsPage.module.scss'
import {NewsService} from "../../service/newsService";
import {Form} from "./Form";
import {List} from "../../сomponents/list/List";
import DeleteConfirmation from "../../сomponents/confirmDelete/DeleteConfirmation";
import {JournalService} from "../../service/journalService";

export const NewsPage = () => {
    const [items, setItems] = useState([])
    const [loading, setLoading] = useState(false);
    const [create, setCreate] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);

    const [search, setSearch] = useState('');
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalItems, setTotalItems] = useState(0);

    const [journals, setJournals] = useState([])
    const [selectedJournalId, setSelectedJournalId] = useState(null)

    const newsService = useMemo(() => new NewsService(), [])
    const journalService = useMemo(() => new JournalService(), [])

    const getAll = useCallback(async () => {
        setLoading(true)
        const [newsRes, journalRes] = await Promise.all([
            newsService.getAll(search, limit, offset, selectedJournalId),
            journalService.getAll()
        ])
        console.log(newsRes)
        if (newsRes.success){
            setItems(newsRes.data)
            setTotalItems(newsRes.pagination.totalItems);
        }
        if(journalRes.success) {
            const res = journalRes.data.map(item => {
                item.click = (item) => {
                    setSelectedJournalId(item.id)
                }
                return item
            })
            setJournals(res)
        }
        setLoading(false)
    }, [newsService, journalService, search, limit, offset, selectedJournalId])

    useEffect(() => {
        getAll().then().catch()
    }, [getAll, search, limit, offset, selectedJournalId]);

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
    };

    const handleLimitChange = (e) => {
        setLimit(parseInt(e.target.value, 10));
    };

    const handlePageChange = (page) => {
        setOffset(page - 1);
    };


    const handleAddClick = () => {
        setCreate(true);
        setEditItem(null);
    }

    const handleEditClick = (item) => {
        setEditItem(item);
        setCreate(true);
    }

    const handleCancel = () => {
        setCreate(false);
        setEditItem(null);
    }

    const handleSave = async (item) => {
        if(item.id) {
            await newsService.edit(item.id, item.title, item.about, item.text, item.journalId)
        } else {
            await newsService.create(item.title, item.about, item.text, item.journalId)

        }
        setCreate(false);
        setEditItem(null);
        getAll()
    }

    const handleDeleteClick = (item) => {
        setDeleteItem(item);
    }

    const handleConfirmDelete = async () => {
        if (deleteItem) {
            try {
                await newsService.delete(deleteItem.id);
                setDeleteItem(null);
                getAll();
            } catch (err) {
                alert(err)
            }
        }
    }

    const handleCancelDelete = () => {
        setDeleteItem(null);
    }

    const handleJournalChange = (e) => {
        const selectedId = e.target.value;
        console.log(selectedId)
        setSelectedJournalId(selectedId ? selectedId : null);

    };

    const columns = [
        {
            header: 'Превью',
            accessor: "images",
            render: (item) => {
                return <>
                    {item.images[0] ? <img src={process.env.REACT_APP_API_BACKEND + "/public" + item.images[0].path} width={50} height={70} alt={item.title}/> : ''}
                </>
            },
        },
        {
            header: 'Название',
            accessor: "title",
            render: (item) => {
                return <>
                    <p style={{fontWeight: 'bold'}}>{item.title}</p>
                </>
            },
        },
        {header: 'Краткое описание', accessor: "about"},
        {header: 'Просмотры', accessor: "views"},
    ]

    return <div className={classes.content}>
        <h2 className={classes.title}>Новости</h2>

        {!create && !deleteItem ? (
            <>
                <div className="flex space-x-2 mb-4">
                    <button className={classes.save} onClick={handleAddClick}>Добавить</button>
                </div>
                <div className="flex flex-col md:flex-row justify-between mb-4">
                    <div className="flex space-x-2">
                        <input
                            type="text"
                            value={search}
                            onChange={handleSearchChange}
                            placeholder="Поиск..."
                            className="px-4 py-2 rounded border border-gray-300"
                        />
                        <select
                            value={limit}
                            onChange={handleLimitChange}
                            className="px-4 py-2 rounded border border-gray-300"
                        >
                            {[10, 20, 50].map(limitOption => (
                                <option key={limitOption} value={limitOption}>{limitOption} на странице</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className='flex items-center flex-row mb-4'>
                    <label className="block text-sm font-medium text-gray-700 mb-1 mr-[10px]">Категория</label>
                    <select
                        value={selectedJournalId || ''}
                        onChange={handleJournalChange}
                        className="px-4 py-2 rounded border border-gray-300"
                    >
                        <option value="">Выберите категорию</option>
                        {journals.map(category => (
                            <option key={category.id} value={category.id}>
                                {category.title} {/* Исправляем для правильного отображения */}
                            </option>
                        ))}
                    </select>
                </div>
                <List columns={columns} loading={loading} items={items} handleEditClick={handleEditClick}
                      handleDeleteClick={handleDeleteClick}/>
                <div className="mt-4 flex justify-center">
                    {Array.from({length: Math.ceil(totalItems / limit)}, (_, index) => (
                        <button
                            key={index}
                            onClick={() => handlePageChange(index + 1)}
                            style={{
                                background: offset === index ? '#3B82F6' : '#E5E7EB',
                                color: offset === index ? 'white' : undefined
                            }}
                            className={`px-4 py-2 rounded   `}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </>
        ) : create ? (
            <Form
                item={editItem}
                onCancel={handleCancel}
                onSave={handleSave}
            />
        ) : deleteItem && (
            <DeleteConfirmation
                title={`Вы уверены, что хотите удалить ${deleteItem.title}?`}
                item={deleteItem}
                onConfirm={handleConfirmDelete}
                onCancel={handleCancelDelete}
            />
        )}
    </div>
}
