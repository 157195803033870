import {instance} from "./https";

export class SlideService {

    token = ''

    constructor() {
        this.token = localStorage.getItem('token')
    }
    get = async () => {
        try {
            const response = await instance.get(`/slide`, {
                headers: {
                    Authorization: this.token,
                    accept: 'application/json'
                },
            });
            return  response.data;
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }

    create = async (data) => {
        try {
            const response = await instance.post(`/slide`, data, {
                headers: {
                    Authorization: this.token,
                    accept: 'application/json'
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
    edit = async (id, data) => {
        try {
            const response = await instance.put(`/slide/${id}`, data, {
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }

    delete = async (id) => {
        try {
            const response = await instance.delete(`/slide/${id}`,{
                headers: {
                    Authorization: this.token,
                },
            });
            const { success } = response.data;
            return success
        } catch (error) {
            console.log('444444444444', error)
            return false
        }
    }
}
